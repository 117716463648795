/* eslint-disable */
//import '@babel/polyfill';
//import Cookies from 'js-cookie';
import { saveContactInformation } from './contact';

const contactForm = document.querySelector('#contactForm');

// **** CONTACT FORM **** //
if (contactForm) {
  contactForm.addEventListener('submit', async (e) => {
    e.preventDefault();

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const subject = document.getElementById('subject').value;
    const phone = document.getElementById('phone').value;
    const message = document.getElementById('message').value;

    await saveContactInformation(name, email, subject, message, phone);
  });
}

// **** DATA TOGGLE **** //
if (window.innerWidth < 992) {
  document.getElementById("dropdown").setAttribute("data-toggle", "dropdown");
}

// **** COOKIES **** //
/*
const consentPropertyName = 'desteldent_cookies_consent';

const shouldShowPopup = () => !Cookies.get(consentPropertyName);
const acceptCookieStorage = () =>
  Cookies.set(consentPropertyName, true, { expires: 365 });
const declineCookieStorage = () =>
  Cookies.set(consentPropertyName, false, { expires: 365 });

window.onload = () => {
  const consentPopup = document.getElementById('consent-popup');
  const acceptBtn = document.getElementById('accept');
  const declineBtn = document.getElementById('decline');

  const acceptFn = (event) => {
    acceptCookieStorage();
    //location.reload();
    consentPopup.classList.add('hidden');
  };

  const declineFn = (event) => {
    declineCookieStorage();
    consentPopup.classList.add('hidden');
  };

  acceptBtn.addEventListener('click', acceptFn);
  declineBtn.addEventListener('click', declineFn);

  if (shouldShowPopup()) {
    setTimeout(() => {
      consentPopup.classList.remove('hidden');
    }, 5000);
  }
};
*/
