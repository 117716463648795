/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const deleteContact = async id => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/contacts/${id}`
    });

    if (res.status === 204) {
      showAlert('success', 'Het bericht is succesvol verwijderd!');
      location.reload(true);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const saveContactInformation = async (name, email, subject, message, phone) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/contacts',
      data: {
        name,
        email,
        subject,
        phone,
        message
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Bedankt om een bericht na te laten.');
      window.setTimeout(() => {
        location.assign('/');
      }, 3000);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
